export const translationsEN = {
  'save': 'Save',
  'send': 'Send',
  'back': 'Back',
  // Auth/Layout.jsx
  'auth': 'Sign in',
  'register': 'Register',
  'reset_pwd': 'Reset Password',
  'edit_pwd': 'Edit Password',
  'confirm': 'Confirmation',
  // Auth/ChangePassword.jsx
  'edit_pwd_1': 'The password reset request is no longer valid.',
  'edit_pwd_2': 'Enter a new password',
  'password': 'Password',
  'repeat_password': 'Repeat Password',
  // Auth/Login.jsx
  'email': 'Email',
  'remember_me': 'Remember me',
  'connect': 'Sign in',
  'continue_with': 'Continue with',
  'processing': 'Processing...',
  'forgot_pwd': 'Forgot Password?',
  // Auth/NewConfirmation.jsx
  'nc_1': 'An email with confirmation instructions has been sent.',
  'nc_2': 'Please check your email account (including SPAM) to proceed.',
  'nc_3': 'Enter your email address to receive a new confirmation email.',
  'connect_1': 'Sign in',
  // Auth/Register.jsx
  'reg_1': 'Fill out the form for a new account:',
  'email_1': 'Your email address',
  'pwd_1': 'Choose a password',
  'pwd_2': 'Confirm password',
  'agree_1': 'I agree with',
  't&c_link': 'terms-and-conditions',
  'agree_2': 'Terms and Conditions',
  'create_account': 'Create Account',
  // Auth/ResetPassword.jsx
  'reset_1': 'An email with password modification details has been sent.',
  'reset_2': 'Please check your email account to proceed.',
  'reset_3': 'Enter your email address',
}
