import React from 'react'
import {withStyles} from '@mui/styles'
import Login from "./Login"
import Register from "./Register"
import ResetPassword from "./ResetPassword"
import ChangePassword from "./ChangePassword"
import NewConfirmation from "./NewConfirmation"
import {Paper, Grid, Avatar, Typography, Divider, FormControl, TextField, MenuItem} from "@mui/material"
import Dashboard from "@mui/icons-material/PermIdentity"
import {green} from "@mui/material/colors"
import translate from './Locales/translate'
import {locales} from '../locales'


const stylesAuth = theme => ({
    root: {
        overflow: 'hidden',
        // padding: `0 ${theme.spacing(3)}`,
        display: 'table',
        position: 'absolute',
        height: '100%',
        width: '100%',
        backgroundImage: "url('/images/b.jpg')",
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% 100%'
    },
    wrapper: {
        display: 'table-cell',
        verticalAlign: 'middle'
    },
    inner: {
        maxWidth: 400,
        maxHeight: 650,
        overflow: 'auto',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    paper: {
        margin: theme.spacing(),
        padding: theme.spacing(2),
        height: '100%',
        // width: '100%'
    },
    avatar: {
        backgroundColor: theme.palette.primary.main
    },
    title: {
        marginTop: 'auto',
        marginBottom: 'auto'
    },
    textField: {
        marginLeft: theme.spacing(),
        marginRight: theme.spacing(),
    },
    button: {
        marginRight: 20,
        marginTop: 30
    },
    checkbox: {
        color: green[600],
        '&$checked': {
            color: green[500],
        },
        alignItems: 'flex-start',
        height: '100%'
    },
    checked: {},
    rowTerms: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
})

class StyledLayout extends React.Component {

    state = {
            step: this.props.step || 1,
            errors: this.props.errors
        }

    baseProps = () => {
        return {
            email: this.props.email,
            account: this.props.account,
            code: this.props.code,
            errors: this.props.errors,
            notices: this.props.notices,
            confirm: this.props.confirm,
            saml_url: this.props.saml_url,
            saml_name: this.props.saml_name,
            email_disabled: this.props.email_disabled,
            login_url: '/accounts/sign_in',
            register_url: '/accounts/sign_up',
            new_confirmation_url: '/accounts/confirmation/new',
            confirmation_url: '/accounts/confirmation',
            reset_url: '/accounts/password/new',
            post_reset_url: '/accounts/password',
            post_edit_password: '/accounts/password',
            reset_password_token: this.props.reset_password_token,
            classes: {
                textField: this.props.classes.textField,
                button: this.props.classes.button,
                checkbox: this.props.classes.checkbox,
                checked: this.props.classes.checked,
                rowTerms: this.props.classes.rowTerms,
            }
        }

    }

    title = () => {
        switch (this.props.step) {
            case 1:
                return translate('auth')
            case 2:
                return translate('register')
            case 3:
                return translate('reset_pwd')
            case 4:
                return translate('edit_pwd')
            case 5:
                return translate('confirm')
            case 6:
                return translate('auth')
            default:
                return ''
        }
    }

    changeLocale = e => {
        let currentUrl = window.location.href
        let newUrl = new URL(currentUrl)
        newUrl.searchParams.set('locale', e.target.value)
        window.location.href = newUrl.toString()
    }

    render() {
        const {classes} = this.props
        return (
                <div className={classes.root}>
                    <div className={classes.wrapper}>
                        <div className={classes.inner}>
                            <Paper className={classes.paper}>
                                <Grid container spacing={2}>
                                    <Grid item>
                                        <Avatar className={classes.avatar}>
                                            <Dashboard/>
                                        </Avatar>
                                    </Grid>
                                    <Grid item xs className={classes.title}>
                                        <Typography variant="h4">{this.title()}</Typography>
                                        <Divider/>
                                    </Grid>
                                </Grid>
                                <div style={{marginTop: 24}}>
                                    {this.state.step === 1 && <Login {...this.baseProps()}/>}
                                    {this.state.step === 2 && <Register  {...this.baseProps()}/>}
                                    {this.state.step === 3 && <ResetPassword  {...this.baseProps()}/>}
                                    {this.state.step === 4 && <ChangePassword  {...this.baseProps()}/>}
                                    {this.state.step === 5 && <NewConfirmation  {...this.baseProps()}/>}
                                </div>
                                
                                <FormControl variant="standard" style={{width: 100}}>
                                    <TextField
                                        variant="standard"
                                        SelectProps={{style: {fontSize: 14}}}
                                        name={"locale"}
                                        value={LOCALE}
                                        onChange={this.changeLocale}
                                        margin="normal"
                                        required={true}
                                        select>
                                        {Object.keys(locales).map(k => <MenuItem key={k} value={k}>{locales[k].toLowerCase()}</MenuItem>)}
                                    </TextField>
                                </FormControl>
                            </Paper>
                        </div>
                    </div>
                </div>
        );
    }
}

export default withStyles(stylesAuth, {withTheme: true})(StyledLayout)